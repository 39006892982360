import { FormComponentProps } from "antd/lib/form";
import React, { useState } from "react";
// import MemoService from "../services/memo.service";
import { AutoComplete, Form, Button } from "antd";

import "./SearchForm.scss";

interface SearchFormProps extends FormComponentProps {
  term?: string
  searchKey: string
  onClear: () => void
  search: (searchKey: string, term: string) => void
}

function SearchFormComponent(props: SearchFormProps) {
  const {getFieldDecorator} = props.form;
  const [suggestions, setSuggestions] = useState([]);

  const search: any = (term: string) => {
    props.search(props.searchKey, term);
  };

  const handleSubmitSearch: any = async (e: any) => {
    e.preventDefault();
    props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        search(values.term);
      }
    });
  };

  const onChange: any = (value: string) => {
    if (value === undefined) props.onClear && props.onClear();
  };

  const suggest = async (term: string) => {
    // TODO: Temporarily disable suggestions because it is not correct
    // if (term.length === 0) return;
    // const suggestionResponse: any = await MemoService.suggestMemo({searchKey: props.searchKey, term});
    // setSuggestions(suggestionResponse.suggest['title-suggestion'][0].options.map((hit: any) => hit.text));
  };

  return (
    <Form className="search-form" layout="inline" onSubmit={handleSubmitSearch}>
      <Form.Item>
        {getFieldDecorator('term', {
          initialValue: props.term
        })(
          <AutoComplete
            className="search-auto-complete"
            dataSource={suggestions}
            onSelect={search}
            onSearch={suggest}
            placeholder="Search..."
            autoFocus={true}
            allowClear={true}
            onChange={onChange}
          >
          </AutoComplete>
        )}
      </Form.Item>

      <Form.Item>
        <Button className="search-btn" icon="search" type="primary" htmlType="submit">
        </Button>
      </Form.Item>
    </Form>
  );
}

const SearchForm = Form.create<SearchFormProps>({name: 'search_form'})(SearchFormComponent);
export default React.memo(SearchForm);
