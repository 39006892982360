import React, { useState, useEffect, useRef } from "react";
import { Layout, Select } from "antd";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { BackTop } from 'antd';

import SearchForm from "../components/SearchForm";
import SearchResults from "../components/SearchResults";
import MemoService from "../services/memo.service";

import "./Home.scss";

const { Header, Footer, Content } = Layout;
const { Option } = Select;

const SEARCH_KEYS = [
  'DIA_CHI_KHACH_HANG',
  'TEN_KHACH_HANG',
  'MA_KHACH_HANG',
  'DIEN_THOAI',
  'XN_CAP_NUOC'
];

let globalSearchKey = '';
let globalSearchTerm = '';

function Home(props: RouteComponentProps) {
  const [searchResults, setSearchResults] = useState({ total: 0, results: [] });
  const [searchKey, setSearchKey] = useState(SEARCH_KEYS[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const backTop = useRef(null);

  const searchPath = props.location.search;
  const params = new URLSearchParams(searchPath);
  const term = params.get('term') || '';

  const handleSearchResponse = (searchResponse: any) => {
    const results = searchResponse.hits.hits.map((hit: any) => {

      return {
        ...hit._source,
        id: hit._id,
        total: searchResponse.hits.total.value,
        highlight: hit.highlight || {},
      }
    });

    const resp = {
      total: searchResponse.hits.total.value,
      results
    };

    setSearchResults(resp);
  };

  const searchByPage = async (page: number) => {
    setCurrentPage(page);
    await search(searchKey, globalSearchTerm, page);
    if (backTop && backTop.current) {
      // @ts-ignore
      backTop.current.scrollToTop();
    }
  };

  const search = async (searchKey: string, term: string, page = 1) => {
    setIsLoading(true);
    if (globalSearchKey !== searchKey || globalSearchTerm !== term) {
      setCurrentPage(1);
    }
    globalSearchKey = searchKey;
    globalSearchTerm = term;
    let searchResponse: any;
    if (term) {
      searchResponse = await MemoService.searchMemo({ searchKey, term, page });
    } else {
      searchResponse = await MemoService.searchMemo({ top: true, page });
    }
    handleSearchResponse(searchResponse);
    setIsLoading(false);
  };

  const handleClearSearchTerm = () => {
    setSearchResults({ total: 0, results: [] });
  };

  const handleChangeSearchKey = (searchKey: string) => {
    setSearchKey(searchKey);
  };

  useEffect(() => {
    search(searchKey, term);
  }, []);

  return (
    <div className="home">
      <BackTop ref={backTop}/>
      <Layout className="main-layout">
        <Layout>
          <Header className="app-header">
            {/*Search Key*/}
            <Select
              placeholder="Search Key"
              defaultValue={SEARCH_KEYS[0]}
              onChange={handleChangeSearchKey}
            >
              {SEARCH_KEYS.map(searchKey => <Option key={searchKey} value={searchKey}>{searchKey.toUpperCase()}</Option>)}
            </Select>
            {/*Search Bar*/}
            <SearchForm
              term={term}
              searchKey={searchKey}
              onClear={handleClearSearchTerm}
              search={search}
            />
          </Header>
          <Content>
            {/*Search Result*/}
            <SearchResults results={searchResults} gotoPage={searchByPage} currentPage={currentPage} isLoading={isLoading}/>
          </Content>
          <Footer style={{ textAlign: 'center' }}>© Copyright 2022 by phieudu241</Footer>
        </Layout>
      </Layout>
      }
    </div>
  );
}

export default withRouter(Home);
