import React from "react";
import { Table } from "antd";

import "./SearchResults.scss";

interface SearchResultsProps {
  results: any
  gotoPage: (page: number) => void
  currentPage: number,
  isLoading: boolean
}

interface DataType {
  MA_KHACH_HANG: string;
  TEN_KHACH_HANG: string;
  DIA_CHI_KHACH_HANG: string;
  DIEN_THOAI: string;
  EMAIL: string;
  NGAY_LAP_DAT: string;
  XN_CAP_NUOC: string;
}

const columns = [
  {
    title: 'MA_KHACH_HANG',
    dataIndex: 'MA_KHACH_HANG',
    key: 'MA_KHACH_HANG',
  },
  {
    title: 'TEN_KHACH_HANG',
    dataIndex: 'TEN_KHACH_HANG',
    key: 'TEN_KHACH_HANG',
  },
  {
    title: 'DIA_CHI_KHACH_HANG',
    dataIndex: 'DIA_CHI_KHACH_HANG',
    key: 'DIA_CHI_KHACH_HANG',
  },
  {
    title: 'DIEN_THOAI',
    dataIndex: 'DIEN_THOAI',
    key: 'DIEN_THOAI',
  },
  {
    title: 'EMAIL',
    dataIndex: 'EMAIL',
    key: 'EMAIL',
  },
  {
    title: 'NGAY_LAP_DAT',
    dataIndex: 'NGAY_LAP_DAT',
    key: 'NGAY_LAP_DAT',
  },
  {
    title: 'XN_CAP_NUOC',
    dataIndex: 'XN_CAP_NUOC',
    key: 'XN_CAP_NUOC',
  },
];

function SearchResults(props: SearchResultsProps) {
  return (
    <>
      <Table
        columns={columns}
        dataSource={props.results.results}
        rowKey={(record) => (record as DataType).MA_KHACH_HANG}
        bordered
        pagination={{
          onChange: page => {
            props.gotoPage(page);
          },
          current: props.currentPage,
          pageSize: 10,
          total: props.results.total,
          position: "both",
          showTotal: function (total) {
            return `Total ${total} items`
          },
          responsive: true
        }}
        loading={props.isLoading}
      />
    </>
  );
}

export default SearchResults;
